import React from "react";

const ResponsiveA = ({ children, ...rest }) => (
  <a
    {...rest}
    className="select-none h-full px-1 mx-1 text-xl lg:text-3xl  cursor-pointer font-bold flex items-center trans-all border-solid border-b-4 border-transparent hover:bg-yellow-400 hover:border-mcb "
  >
    {children}
  </a>
);

const HOne = ({ children, className = "", ...rest }) => (
  <h1
    className={"text-4xl lg:text-max font-bold " + className}
    {...rest}
    style={{ lineHeight: 1.08 }}
  >
    {children}
  </h1>
);

const HTwo = ({ children, ...rest }) => (
  <h2 className="mt-10 font-bold text-xl lg:text-3xl leading-tight" {...rest}>
    {children}
  </h2>
);

const HeaderContent = () => (
  <main>
    <HOne className="mt-32">Silverlining</HOne>
    <HOne>乌云背后的幸福线</HOne>
    <div className="h-2 lg:h-3 bg-mcb w-40 lg:w-64 mt-20"></div>
    <HTwo>Binghua 的个人主页，记录和分享技术，生活，思路。</HTwo>
  </main>
);

export default function Landing(props) {
  return (
    <React.Fragment>
      <header
        className="overflow-hidden bg-mcy w-screen h-screen"
        style={{ minHeight: 568, maxHeight: 1200 }}
      >
        <div className="w-10/12 mx-auto">
          <nav className="w-full flex justify-end h-12 lg:h-16 items-center ">
            <ResponsiveA>Blogs</ResponsiveA>
            <ResponsiveA>About</ResponsiveA>
            <ResponsiveA>RSS</ResponsiveA>
          </nav>
          <HeaderContent />
        </div>
      </header>
    </React.Fragment>
  );
}
