import React from "react";
import Landing from "./landing";

function App() {
  return (
    <>
      <Landing />
      {/* <div className="max-w-sm mx-auto shadow-xl md:flex bg-white rounded-lg p-6">
        <img
          alt="logo"
          className="h-16 w-16 md:h-24 md:w-24 rounded-full mx-auto md:mx-0 md:mr-6"
          src={logo}
        />
        <div className="text-center md:text-left">
          <h2 className="text-lg">Erin Lindford</h2>
          <div className="text-purple-500">Customer Support</div>
          <div className="text-gray-600">erinlindford@example.com</div>
          <div className="text-gray-600">(555) 765-4321</div>
        </div>
      </div> */}
    </>
  );
}

export default App;
